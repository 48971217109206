import React from "react"

// Styles
import './NavigationArrow.scss';

const NavigationArrow = ( { type, sliderRef, hover } ) => {

    const updateNavigationIcon = ( event, eventType, hover) => {
        let navigationIcon = event.target.getElementsByTagName( 'img' );
        
        //console.log("hover", hover);
        if ( navigationIcon && navigationIcon[ 0 ] ) {
            let icon = '';
            if(hover === "false"){
                icon = 'navigation-left-white-icon.png';
            } else{
                icon = eventType === 'enter' ? 'navigation-left-black-icon.png' : 'navigation-left-white-icon.png';
            }
            
            navigationIcon[ 0 ].src = require( `../../images/${ icon }` ).default;
        }
    }

    const navigateSlider = ( type ) => {
        if ( sliderRef && type ) {
            if ( type === 'left' ) {
                sliderRef.current.slickPrev();
            } else {
                sliderRef.current.slickNext();
            }
        }
    }

    return (
        <span
            className={ `navigation-arrow-wrapper ${ type || '' }` }
            onMouseEnter={ e => updateNavigationIcon( e, 'enter', hover) }
            onMouseLeave={ e => updateNavigationIcon( e, 'leave', hover) }
            onClick={ () => navigateSlider( type ) }
        >
            <img
                src={ require( `../../images/navigation-left-white-icon.png` ).default }
                alt="navigation icon"
            />
        </span>
    )
}

export default NavigationArrow
