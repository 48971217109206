// Price Min
export const priceminRange = [
    {label: "Min Price", value: "0"},
    {label: "£200 p/w", value: "200"},
    {label: "£300 p/w", value: "300"},
    {label: "£400 p/w", value: "400"},
    {label: "£500 p/w", value: "500"},
    {label: "£600 p/w", value: "600"},
    {label: "£700 p/w", value: "700"},
    {label: "£800 p/w", value: "800"},
    {label: "£900 p/w", value: "900"},
    {label: "£1000 p/w", value: "1000"},
    {label: "£1500 p/w", value: "1500"},
]
// Price Min
  
// Price Max
export const pricemaxRange = [
    {label: "Max Price", value: "0"},
    {label: "£2000 p/w", value: "2000"},
    {label: "£1500 p/w", value: "1500"},
    {label: "£1000 p/w", value: "1000"},
    {label: "£900 p/w", value: "900"},
    {label: "£800 p/w", value: "800"},
    {label: "£700 p/w", value: "700"},
    {label: "£600 p/w", value: "600"},
    {label: "£500 p/w", value: "500"},
    {label: "£400 p/w", value: "400"},
    {label: "£300 p/w", value: "300"},
]
// Price Max

// Bedrooms
export const bedroomOptions = [
    {label: "Studio", value: "0"},
    {label: "1+ beds", value: "1"},
    {label: "2+ beds", value: "2"},
    {label: "3+ beds", value: "3"},
    {label: "4+ beds", value: "4"},
]
// Bedrooms

// 
export const lettingsType = [
    {label: "Lettings Type", value: ""},
    {label: "Long Lets", value: "long-lets"},
    {label: "Short Lets", value: "short-lets"},
]
// 

// 
export const propertyType = [
    {label: "Property Type", value: ""},
    {label: "Apartment", value: "Apartment"},
    {label: "House", value: "House"},
]
// 

// Amenities
export const amenitiesCheck = [
    {
        value: 'Broadband',
        label: 'Broadband'
    }, {
        value: 'GymOrSpa',
        label: 'Gym Or Spa'
    },{
        value: 'Gardens',
        label: 'Gardens'
    },{
        value: 'Shops',
        label: 'Shops'
    },{
        value: 'Wifi',
        label: 'Wifi'
    },{
        value: 'DigitalTV',
        label: 'Digital TV'
    },{
        value: 'Pool',
        label: 'Pool'
    },{
        value: 'CashMachine',
        label: 'Cash Machine'
    },{
        value: 'Parking',
        label: 'Parking'
    },{
        value: 'AirCon',
        label: 'Air Con'
    },{
        value: 'Sports',
        label: 'Sports'
    },{
        value: 'Porter',
        label: 'Porter'
    },{
        value: 'Lifts',
        label: 'Lifts'
    },{
        value: 'Restaurant',
        label: 'Restaurant'
    },{
        value: 'CCTV',
        label: 'CCTV'
    },{
        value: 'VideoEntry',
        label: 'Video Entry'
    }
]
// Amenities

// Home price range
export const homePriceRange = [
    {label: "£500 p/w - £1000 p/w", value: "500-1000"},
    {label: "£1000 p/w - £2000 p/w", value: "1000-2000"},
    {label: "£2000 p/w - £3000 p/w", value: "2000-3000"},
    {label: "£3000 p/w - £5000 p/w", value: "3000-5000"},
]
// Home price range

// Home Bedrooms
export const homeBedroomOptions = [
    {label: "1 bed +", value: "1"},
    {label: "2 bed +", value: "2"},
    {label: "3 bed +", value: "3"},
    {label: "4 bed +", value: "4"},
]
// Home Bedrooms

// Agent Dashboard Dropdown
export const saleType = [
    {
        display: 'To Rent',
        value: 'To Rent'
    },
    {
        display: 'For Sale',
        value: 'For Sale'
    },
];

export const minBedroomOptions = [
    {
        display: 'Bedrooms',
        value: 'All'
    },
    {
        display: 'Studio',
        value: '0'
    },
    {
        display: '1 bed',
        value: '1'
    },
    {
        display: '2 beds',
        value: '2'
    },
    {
        display: '3 beds',
        value: '3'
    },
    {
        display: '4+ beds',
        value: '4+'
    },
];
// Agent Dashboard Dropdown


// Property Details Link
export const propertyDetailsLink = (department, search_type, slug, id) => {
    //console.log("menu_data", menu_data);
    var link = "";
    if(department === "residential" && search_type === "sales"){
		link = '/residential-property-for-sale';
	} else if(department === "residential" && search_type === "lettings"){
		link = '/residential-property-to-rent';
	} else if(department === "commercial" && search_type === "sales"){
		link = '/commercial-property-for-sale';
	} else if(department === "commercial" && search_type === "lettings"){
		link = '/commercial-property-to-rent';
	}
    link += '/'+slug+'-'+id+'/';
    
    return link;
}

export const getDaysCount = (days) => {
    var days_string = "";

    if(days > 0){
        if(days > 1){
            days_string = `${days} nights`;
        } else{
            days_string = `${days} night`;
        }
    }

    return days_string;
}

// Per Week, Month, Annum
export const getDaysQualifier = {
    per_week: 7,
    per_month: 30,
    per_annum: 365
}
// Home price range

export const PropertyDetails =  {
    residential: {
        sales: "residential-property-for-sale",
        lettings: "residential-property-to-rent",
    },
    commercial: {
        sales: "commercial-property-for-sale",
        lettings: "commercial-property-to-rent",
    }
}
// Property Details Link

// Price Options
export const priceOptions = [
    { currency: '£', name:"pound" },
    { currency: '$', name:"dollar" },
    { currency: '€', name:"euro" },
];